import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './index.css';

import Mainpage from './pages/Mainpage';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Dyno from './pages/Dyno';
import DynoPrivacy from './pages/DynoPrivacy';
import DecimalPrivacy from './pages/DecimalPrivacy';
import SplinterPrivacy from './pages/SplinterPrivacy';
import WriteForUs from './pages/WriteForUs';
import CreatingUrgency from './pages/blog-posts/CreatingUrgency';
import DiscountCodeEtiquette from './pages/blog-posts/DiscountCodeEtiquette';
import LoyaltyRewards from './pages/blog-posts/LoyaltyRewards';
import MazimizeSales from './pages/blog-posts/MazimizeSales';
import PsychologyOfDiscounts from './pages/blog-posts/PsychologyOfDiscounts';
import TrackingROI from './pages/blog-posts/TrackingROI';
import UniqueDiscountCodes from './pages/blog-posts/UniqueDiscountCodes';

// import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Mainpage/>,
  },{
    path: "/blog",
    element: <Blog/>,
  },{
    path: "/write-for-us",
    element: <WriteForUs/>,
  },{
    path: "/about",
    element: <Blog/>,
  },{
    path: "/contact",
    element: <Contact/>,
  },{
    path: "/faqs",
    element: <Blog/>,
  },{
    path: "/volt-low-stock-alerts",
    element: <Blog/>,
  },{
    path: "/dyno-bulk-discount-codes",
    element: <Dyno/>,
  },{
    path: "/dyno-bulk-discount-codes/privacy",
    element: <DynoPrivacy/>,
  },{
    path: "/spinter-split-orders/privacy",
    element: <SplinterPrivacy/>,
  },{
    path: "/decimal-discounts/privacy",
    element: <DecimalPrivacy/>,
  },
  {
    path: "/5-essential-tips-maximizing-sales-in-shopify",
    element: <MazimizeSales/>,
  },
  {
    path: "/creating-a-sense-of-urgency",
    element: <CreatingUrgency/>,
  },
  {
    path: "/discount-code-etiquette",
    element: <DiscountCodeEtiquette/>,
  },
  {
    path: "/loyalty-rewards-programs",
    element: <LoyaltyRewards/>,
  },
  {
    path: "/psychology-of-discounts",
    element: <PsychologyOfDiscounts/>,
  },
  {
    path: "/tracking-roi-impact-of-discount-codes",
    element: <TrackingROI/>,
  },
  {
    path: "/unique-discount-codes",
    element: <UniqueDiscountCodes/>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();