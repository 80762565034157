import { Helmet } from 'react-helmet';

import Navbar from '../components/Navbar';
import SplinterPrivacyBody from '../components/SplinterPrivacyBody';
import Footbar from '../components/Footbar';
 
import './App.css';

function SplinterPrivacy() {
  return (
    <div>
      <Helmet>
        <title>{ "ByloByte | Splinter | Privacy" }</title>
        <meta name='description' content= 'Learn about our privacy policy at ByloByte | Splinter. We are committed to protecting your data in how we collect, use, and secure your information.'/>
      </Helmet>

      <Navbar/>
      <SplinterPrivacyBody />
      <Footbar />
    </div>
  );
}

export default SplinterPrivacy;
