const ContactBody = () => {

  return (
    <div className=''>
      <div className="container center-max-width mt-5">
          <div className='container card-box p-4'>
            <div>
              <h2 variant="headingXl" as="h4">
                Contact us
              </h2>
              <p>
                Hello there! We're here to assist you with any questions, concerns, or just a friendly chat. 
                At ByloByte (also known as "Verumus"), we are committed to providing round-the-clock support 
                to ensure your experience is seamless. Whether you prefer reaching out via call, email, or 
                our social channels, we're just a message away. Your satisfaction is our priority, and we 
                look forward to connecting with you!
              </p>
              <div alignItems="center" columns={3}>
                  <ul type="bullet">
                    <li><a href="https://wa.me/+15513732595" target="_blank">Whatsapp</a></li>
                    <li><a href="https://t.me/verumus" target="_blank">Telegram</a></li>
                    <li>Phone number: <a href="tel:+15513732595" target="_blank">+1 (551) 373-2595</a></li>
                    <li>E-mail: <a href="mailto:info@bylobyte.com" target="_blank">info@bylobyte.com</a></li>
                  </ul>
              </div>
            </div>
          </div>

          <div className='container card-box mt-5 p-4'>
            <div>
              <h2 variant="headingXl" as="h4">
                Privacy
              </h2>
              <p>
                Last updated: 02/19/2025
              </p>
              <p>
                ByloByte (also known as "Verumus") ("us", "we", or "our") operates the Order Splitting Automation app (the "Service") on the Shopify platform.
                This page informs you of our policies regarding the collection, use, and disclosure of Personal Information when you use our Service.
              </p>
              <p>
                We do not use or share your information with anyone except as described in this Privacy Policy.
              </p>
              <h2 variant="headingMd" as="h6">
                How It Works
              </h2>
              <p>
                Our app automates the process of splitting orders into child orders based on the rules you define. When an order is created, the app listens for the <b>orders/create</b> webhook.
                Upon receiving the webhook, the app performs the following steps:
              </p>
              <ul type="bullet">
                <li><b>Identify:</b> Analyze the incoming order to determine which items meet your splitting criteria.</li>
                <li><b>Create Child Orders:</b> Generate new child orders for items that meet the split criteria.</li>
                <li><b>Adjust Parent Order:</b> Remove identified items from the parent order.</li>
                <li><b>Refund Process:</b> Process an internal refund for the removed items to update inventory without affecting customer payment.</li>
              </ul>
              <h2 variant="headingMd" as="h6">
                Data Usage and Privacy Agreement
              </h2>
              <p>
                By using this app, you agree to our data processing policies and acknowledge that we collect and process only the minimum personal data required to provide value to merchants. 
                The data we process includes:
              </p>
              <ul type="bullet">
                <li>Shipping and billing addresses</li>
                <li>Customer name, last name, phone number, and email address</li>
              </ul>
              <p>
                We do not store any customer data beyond its necessary use for processing orders.
              </p>
              <h2 variant="headingMd" as="h6">
                Access Scopes
              </h2>
              <p>
                Our app requires the following permissions to function properly:
              </p>
              <ul type="bullet">
                <li><b>read_locations, read_products, read_inventory:</b> To identify items and apply split rules.</li>
                <li><b>read_orders:</b> Required to receive order data from the <b>orders/create</b> webhook.</li>
                <li><b>write_orders:</b> Necessary to create child orders once split criteria are met.</li>
              </ul>
              <h2 variant="headingMd" as="h6">
                Data Privacy and Security
              </h2>
              <p>
                We prioritize data security and privacy. Our app does not store any order, customer, or store data. All data is processed in real-time without being stored on our systems.
              </p>
              <p>
                The only data we store relates to split rules, which contain no sensitive information regarding customers, orders, or products.
              </p>
            </div>
          </div>
        </div>
    </div>
  );
}

export default ContactBody;
