const ContactBody = () => {
  return (
    <div className=''>
      <div className="container center-max-width mt-5">
        <div className='container card-box p-4'>
          <div>
            <h2 variant="headingXl" as="h4">
              Contact us
            </h2>
            <p>
              Hello there! We're here to assist you with any questions, concerns, or just a friendly chat. 
              At Decimal Discounts, we are committed to providing round-the-clock support to ensure your experience is seamless. 
              Whether you prefer reaching out via call, email, or our social channels, we're just a message away. 
              Your satisfaction is our priority, and we look forward to connecting with you!
            </p>
            <div alignItems="center" columns={3}>
              <ul type="bullet">
                <li><a href="https://wa.me/+15513732595" target="_blank">Whatsapp</a></li>
                <li><a href="https://t.me/verumus" target="_blank">Telegram</a></li>
                <li>Phone number: <a href="tel:+15513732595" target="_blank">+1 (551) 373-2595</a></li>
                <li>E-mail: <a href="mailto:info@bylobyte.com" target="_blank">info@bylobyte.com</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div className='container card-box mt-5 p-4'>
          <div>
            <h2 variant="headingXl" as="h4">
              Privacy
            </h2>
            <p>
              Last updated: 02/19/2025
            </p>
            <p>
              Decimal Discounts ("us", "we", or "our") operates the Decimal Discounts app (the "Service") on the Shopify platform.
              This page informs you of our policies regarding the collection, use, and disclosure of personal information when you use our Service.
            </p>
            <p>
              We do not use or share your information with anyone except as described in this Privacy Policy.
            </p>
            <h2 variant="headingMd" as="h6">
              How It Works
            </h2>
            <p>
              Our app modifies existing discount percentage values, converting them into decimal format for enhanced precision.
              The app listens for discount updates and applies changes automatically without requiring manual intervention.
            </p>
            <h2 variant="headingMd" as="h6">
              Data Usage and Privacy Agreement
            </h2>
            <p>
              By using this app, you agree to our data processing policies and acknowledge that we collect and process only the minimum personal data required to provide value to merchants.
            </p>
            <p>
              However, Decimal Discounts does not store any merchant, customer, or discount data. All modifications are applied in real-time without any data retention.
            </p>
            <h2 variant="headingMd" as="h6">
              Access Scopes
            </h2>
            <p>
              Our app requires the following permissions to function properly:
            </p>
            <ul type="bullet">
              <li><b>read_discounts:</b> To retrieve and analyze existing discount rules.</li>
              <li><b>write_discounts:</b> Necessary to modify discount values with decimal precision.</li>
            </ul>
            <h2 variant="headingMd" as="h6">
              Data Privacy and Security
            </h2>
            <p>
              We prioritize data security and privacy. Our app does not store any discount, customer, or store data. All modifications occur in real-time and are not retained on our systems.
            </p>
            <p>
              The only data processed is discount information necessary for performing modifications, and no sensitive customer or merchant data is accessed or stored.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactBody;
