import { Helmet } from 'react-helmet';

import Navbar from '../components/Navbar';
import DecimalPrivacyBody from '../components/DecimalPrivacyBody';
import Footbar from '../components/Footbar';
 
import './App.css';

function DecimalPrivacy() {
  return (
    <div>
      <Helmet>
        <title>{ "ByloByte | Decimal | Privacy" }</title>
        <meta name='description' content= 'Learn about our privacy policy at ByloByte | Decimal. We are committed to protecting your data in how we collect, use, and secure your information.'/>
      </Helmet>

      <Navbar/>
      <DecimalPrivacyBody />
      <Footbar />
    </div>
  );
}

export default DecimalPrivacy;
